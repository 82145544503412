import { HttpClient, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ApiService } from './api.service';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  constructor(private apiService: ApiService, private httpClient: HttpClient, private configService: ConfigService) { }

  uploadFile(item: any, filename: string) {
    var formData = new FormData();
      formData.append('file', item.file, item.file.name);
      const req = new HttpRequest('POST', `${ this.configService.Settings.apiServerHost }/${this.configService.Settings.fileUri}/${item.type}/${filename}`, formData, {
        reportProgress: true
      });
      
      const progress = new Subject<number>();

      this.httpClient.request(req).subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {

          // calculate the progress percentage
          const percentDone = Math.round(100 * event.loaded / event.total);

          // pass the percentage into the progress-stream
          progress.next(percentDone);
        } else if (event instanceof HttpResponse) {

          // Close the progress-stream if we get an answer form the API
          // The upload is complete
          progress.complete();
        }
      });
  }
}
