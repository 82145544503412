import { Injectable } from '@angular/core';
import { ConfigService } from '../services/common/config.service';
import { ApiService } from '../services/common/api.service';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  redirectUrl: string = '';

  constructor(private configService: ConfigService, private apiService: ApiService) { }

  login(username: string, password: string) {
    var user = new User();
    user.username = username;
    user.password = password;

    return this.apiService.post(user, this.configService.Settings.apiServerHost + this.configService.Settings.usersUri + '/authenticate');
  }
}
