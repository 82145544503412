import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { AppRoutingModule } from './app-routing.module';
import { DashboardComponent } from './home/dashboard/dashboard.component';
import { HeaderComponent } from './common/header/header.component';
import { FooterComponent } from './common/footer/footer.component';
import { ApiService, TokenInterceptor } from './services/common/api.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ConfigService } from './services/common/config.service';
import { SettingComponent } from './user/setting/setting.component';
import { NotSupportedComponent } from './common/not-supported/not-supported.component';
import { InventoryListComponent } from './business/inventory-list/inventory-list.component';
import { SearchPipe } from './pipes/search.pipe';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { LoadingAnimationComponent } from './common/loading-animation/loading-animation.component';
import { InventoryDetailComponent } from './business/inventory-detail/inventory-detail.component';
import { CustomerPoListComponent } from './business/customer-po-list/customer-po-list.component';
import { CustomerPoDetailComponent } from './business/customer-po-detail/customer-po-detail.component';
import { SupplierPoListComponent } from './business/supplier-po-list/supplier-po-list.component';
import { SupplierPoDetailComponent } from './business/supplier-po-detail/supplier-po-detail.component';
import { SupplierInvoiceListComponent } from './business/supplier-invoice-list/supplier-invoice-list.component';
import { SupplierInvoiceDetailComponent } from './business/supplier-invoice-detail/supplier-invoice-detail.component';
import { ShipmentListComponent } from './business/shipment-list/shipment-list.component';
import { ShipmentUploadPosComponent } from './business/shipment-upload-pos/shipment-upload-pos.component';
import { DEFAULT_TIMEOUT, TimeoutInterceptor } from './services/common/http.interceptor';
import { ICachingService } from './services/common/interface/ICachingService';
import { DictionaryCachingService } from './services/common/cache.service';
import { ScanBarcodeComponent } from './business/scan-barcode/scan-barcode.component';
import { BarecodeScannerLivestreamModule } from 'ngx-barcode-scanner';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    DashboardComponent,
    HeaderComponent,
    FooterComponent,
    SettingComponent,
    NotSupportedComponent,
    InventoryListComponent,
    SearchPipe,
    LoadingAnimationComponent,
    InventoryDetailComponent,
    CustomerPoListComponent,
    CustomerPoDetailComponent,
    SupplierPoListComponent,
    SupplierPoDetailComponent,
    SupplierInvoiceListComponent,
    SupplierInvoiceDetailComponent,
    ShipmentListComponent,
    ShipmentUploadPosComponent,
    ScanBarcodeComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    FormsModule,
    BarecodeScannerLivestreamModule
  ],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy} ,
    [{ provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true }],
    [{ provide: DEFAULT_TIMEOUT, useValue: 300000 }],
    [{ provide: ICachingService, useClass: DictionaryCachingService }],
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    ApiService,
    ConfigService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
