import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './home/dashboard/dashboard.component';
import { AuthGuard } from './auth.guard';
import { SettingComponent } from './user/setting/setting.component';
import { NotSupportedComponent } from './common/not-supported/not-supported.component';
import { InventoryListComponent } from './business/inventory-list/inventory-list.component';
import { InventoryDetailComponent } from './business/inventory-detail/inventory-detail.component';
import { CustomerPoListComponent } from './business/customer-po-list/customer-po-list.component';
import { CustomerPoDetailComponent } from './business/customer-po-detail/customer-po-detail.component';
import { SupplierPoDetailComponent } from './business/supplier-po-detail/supplier-po-detail.component';
import { SupplierPoListComponent } from './business/supplier-po-list/supplier-po-list.component';
import { SupplierInvoiceListComponent } from './business/supplier-invoice-list/supplier-invoice-list.component';
import { SupplierInvoiceDetailComponent } from './business/supplier-invoice-detail/supplier-invoice-detail.component';
import { ShipmentListComponent } from './business/shipment-list/shipment-list.component';
import { ShipmentUploadPosComponent } from './business/shipment-upload-pos/shipment-upload-pos.component';
import { ScanBarcodeComponent } from './business/scan-barcode/scan-barcode.component';

const routes: Routes = [
  {
      path: '',
      component: LoginComponent,
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'settings',
    component: SettingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'not-supported',
    component: NotSupportedComponent,
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: DashboardComponent
      }, {
        path: 'inventory/list',
        component: InventoryListComponent
      }, {
        path: 'inventory/detail/:id',
        component: InventoryDetailComponent
      }, {
        path: 'customerpo/list/:cache/:id',
        component: CustomerPoListComponent
      }, {
        path: 'customerpo/detail',
        component: CustomerPoDetailComponent
      }, {
        path: 'supplierpo/list/:cache/:id',
        component: SupplierPoListComponent
      }, {
        path: 'supplierpo/detail',
        component: SupplierPoDetailComponent
      }, {
        path: 'supplierinvoice/list/:cache',
        component: SupplierInvoiceListComponent
      }, {
        path: 'supplierinvoice/detail',
        component: SupplierInvoiceDetailComponent
      }, {
        path: 'shipment/list/:cache/:id',
        component: ShipmentListComponent
      }, {
        path: 'shipment/upload-pos',
        component: ShipmentUploadPosComponent
      }, {
        path: 'shipment/scan-barcode',
        component: ScanBarcodeComponent
      }
    ]
  }  
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, { useHash: true })
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})


export class AppRoutingModule { }