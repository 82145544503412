import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BarecodeScannerLivestreamComponent } from 'ngx-barcode-scanner';
import { LoadingAnimationService } from 'src/app/common/loading-animation/loading-animation.service';
import { PackingSlipBoxDetails, Shipment } from 'src/app/models/shipment.model';
import { DataShareService } from 'src/app/services/common/data-share.service';
import { ShipmentService } from 'src/app/services/shipment.service';

@Component({
  selector: 'app-scan-barcode',
  templateUrl: './scan-barcode.component.html',
  styleUrls: ['./scan-barcode.component.css']
})
export class ScanBarcodeComponent implements OnInit, AfterViewInit {

  @ViewChild(BarecodeScannerLivestreamComponent)
  barcodeScanner: BarecodeScannerLivestreamComponent;
  
  private barcodeValue: any;
  private shipment: Shipment;
  private packingSlipBoxDetails: PackingSlipBoxDetails[] = [];

  constructor(private router: Router, private dataShareService: DataShareService, private shipmentService: ShipmentService,
              private loadingAnimationService: LoadingAnimationService) { }
  
  ngAfterViewInit(): void {
    this.barcodeScanner.start();
  }

  ngOnInit() {
    this.dataShareService.currentMessage.subscribe((data: Shipment) => {
      this.shipment = data;
      this.showBarcodeGrid();
    });
  }

  onValueChanges(event) {
    this.barcodeValue = event.codeResult.code;
    alert(this.barcodeValue);
    
    if (this.barcodeValue && this.packingSlipBoxDetails.find(p => p.barcode == this.barcodeValue) == null) {
      alert('Scanned item does not exist in this list. Please check.');
    }
  }

  onStarted() {
    console.log('started');
  }

  showBarcodeGrid() {
    if (this.shipment) {
      this.shipment.packingSlipDetails.forEach(packingSlipDetail => {
        this.packingSlipBoxDetails.push(...packingSlipDetail.packingSlipBoxDetails);
      });
      console.log(this.packingSlipBoxDetails);
    }
  }

  completeScan() {
    if (!this.barcodeValue) {
      alert('Please scan the barcode');
      return;
    }

    if (this.packingSlipBoxDetails.length == 0) {
      alert('There are not barcodes to scan');
      return;
    }

    if (this.packingSlipBoxDetails.filter(b => b.barcode == this.barcodeValue).length == 0) {
      alert('There are no matching barcode in this shipment to scan');
      return;
    }

    if (this.packingSlipBoxDetails.filter(b => b.barcode == this.barcodeValue && b.isScanned == true).length > 0) {
      alert('This barcode is already been scanned');
      return;
    }

    this.loadingAnimationService.show();
    this.shipmentService.completeScanning(this.shipment, this.barcodeValue)
        .subscribe(result => {
          this.packingSlipBoxDetails.find(p => p.barcode == this.barcodeValue).isScanned = true;
          this.loadingAnimationService.hide();
          alert(`Barcode ${ this.barcodeValue } scanned successfully!!`);
          this.barcodeValue = '';
        }, (error) => {
          alert(error.error);
          this.loadingAnimationService.hide();
        });
  }

  navigateBack() {
    this.router.navigateByUrl(`home/shipment/list/true/${ this.shipment.customerId }`);
  }
}