import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ICachingService } from './interface/ICachingService';

@Injectable({
  providedIn: 'root'
})
export class CacheService implements ICachingService {

  constructor() { }

  clearAll() {
    localStorage.clear();
  }

  setData(data: any, key: string) {
    localStorage.setItem(key, JSON.stringify(data));
  }

  hasKey(key: string): boolean {
    return localStorage.getItem(key) != null;
  }

  getData(key: string) : Observable<any> {
    var data = localStorage.getItem(key);
    return of(JSON.parse(data));
  }

  removeData(key: string) {
    localStorage.removeItem(key);
  }
}

@Injectable({
  providedIn: 'root'
})
export class DictionaryCachingService implements ICachingService {

  private dictionaryMap = new Map<string, any>();

  clearAll() {
    this.dictionaryMap.clear();
  }

  setData(data: any, key: string) {
    this.dictionaryMap.set(key, data);
  }

  hasKey(key: string): boolean {
    return this.dictionaryMap.has(key);
  }

  getData(key: string): Observable<any> {
    var data = this.dictionaryMap.get(key);
    return of(data);
  }

  removeData(key: string) {
    this.dictionaryMap.delete(key);
  }
}