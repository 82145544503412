import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  get Settings(): AppConfiguration {
    return new AppConfiguration();
  }

}

export class AppConfiguration {

  constructor() {
    if (environment.production) {
      this.apiServerHost = 'https://invoice.harisons.com/api';
      this.reportsUri = 'https://invoice.harisons.com/reports/ReportViewer/'
    } else {
      this.apiServerHost = 'http://cf.globalexportech.com/api';
      this.reportsUri = 'http://cf.globalexportech.com/reports/ReportViewer/';
    }
  }

  apiServerHost: string;
  reportsUri: string;

  usersUri: string = '/users';
  partsUri: string = '/mobileparts';
  ordersUri: string = '/mobileorders';
  supplierOrdersUri: string = '/mobilepos';
  supplierInvoiceUri: string = '/MobileSupplierInvoice';
  postSupplierinvoiceUri: string = '/supplierinvoice';
  fileUri: string = '/File';
  customerUri: string = '/customers';
  supplierUri: string = '/suppliers';
  shipmentUri: string = '/PackingSlips';
}