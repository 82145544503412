import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Shipment } from 'src/app/models/shipment.model';
import { DataShareService } from 'src/app/services/common/data-share.service';
import { FileUploadService } from 'src/app/services/common/file-upload.service';

@Component({
  selector: 'app-shipment-upload-pos',
  templateUrl: './shipment-upload-pos.component.html',
  styleUrls: ['./shipment-upload-pos.component.css']
})
export class ShipmentUploadPosComponent implements OnInit {

  private file: FileList;
  private shipment: Shipment;

  constructor(private router: Router, private sharedDataService: DataShareService, private fileService: FileUploadService) { }

  ngOnInit() {
    this.sharedDataService.currentMessage.subscribe((data: Shipment) => {
      this.shipment = data;
    });
  }

  navigateBack() {
    this.router.navigateByUrl(`home/shipment/list/false/${ this.shipment.customerId }`);
  }
  
  uploadFile(file: FileList) {
    this.file = file;
  }

  upload() {
    var trackingNumber = this.shipment.trakingNumber? this.shipment.trakingNumber: '_';
      this.fileService.uploadFile({'type': 'POS', 'file': this.file[0]}, `${this.shipment.id}/${ trackingNumber }`);
      alert('Document uploaded successfully!!');
  }
}