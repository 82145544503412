import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SupplierInvoice } from 'src/app/models/supplier-invoice.model';
import { ConfigService } from 'src/app/services/common/config.service';
import { DataShareService } from 'src/app/services/common/data-share.service';

@Component({
  selector: 'app-supplier-invoice-detail',
  templateUrl: './supplier-invoice-detail.component.html',
  styleUrls: ['./supplier-invoice-detail.component.css']
})
export class SupplierInvoiceDetailComponent implements OnInit {

  private supplierInvoice: SupplierInvoice;

  constructor(private router: Router, private sharedDataService: DataShareService, private configService: ConfigService) { }

  ngOnInit() {
    this.sharedDataService.currentMessage.subscribe((data: SupplierInvoice) => {
      this.supplierInvoice = data;
    });
  }

  navigateBack() {
    this.router.navigateByUrl('home/supplierinvoice/list/false');
  }

  downloadInvoice() {
    if (!this.supplierInvoice.isInvoiceUploaded) {
      alert('Document unavailable.');
      return;
    }
    window.open(`${ this.configService.Settings.apiServerHost }/${ this.configService.Settings.fileUri }/Invoice/${ this.supplierInvoice.id }`);
  }

  downloadPacking() {
    if (!this.supplierInvoice.isPackingSlipUploaded) {
      alert('Document unavailable.');
      return;
    }
    window.open(`${ this.configService.Settings.apiServerHost }/${ this.configService.Settings.fileUri }/PackingSlip/${ this.supplierInvoice.id }`);
  }

  downloadTenPlusTwo() {
    if (!this.supplierInvoice.isTenPlusUploaded) {
      alert('Document unavailable.');
      return;
    }
    window.open(`${ this.configService.Settings.apiServerHost }/${ this.configService.Settings.fileUri }/TenPlus/${ this.supplierInvoice.id }`);
  }

  downloadBL() {
    if (!this.supplierInvoice.isBLUploaded) {
      alert('Document unavailable.');
      return;
    }
    window.open(`${ this.configService.Settings.apiServerHost }/${ this.configService.Settings.fileUri }/BL/${ this.supplierInvoice.id }`);
  }

  downloadTC() {
    if (!this.supplierInvoice.isTCUploaded) {
      alert('Document unavailable.');
      return;
    }
    window.open(`${ this.configService.Settings.apiServerHost }/${ this.configService.Settings.fileUri }/TC/${ this.supplierInvoice.id }`);
  }
}