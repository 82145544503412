import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from './login.service';
import { User } from '../models/user.model';
import { ICachingService } from '../services/common/interface/ICachingService';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  private username: string = '';
  private password: string = '';
  private error: string = '';

  constructor(private route: Router, private loginService: LoginService, private cachingService: ICachingService) { }

  ngOnInit() {
    localStorage.clear();
    var mobileBrowser = navigator.userAgent.match(/Android/i) 
    || navigator.userAgent.match(/webOS/i) 
    || navigator.userAgent.match(/iPhone/i)  
    || navigator.userAgent.match(/iPad/i)  
    || navigator.userAgent.match(/iPod/i) 
    || navigator.userAgent.match(/BlackBerry/i) 
    || navigator.userAgent.match(/Windows Phone/i)
    if (!mobileBrowser) {
      localStorage.clear();
      this.route.navigateByUrl('not-supported');
    }
  }

  login() {
    this.loginService.login(this.username, this.password)
        .subscribe((result: User) => {
          localStorage.setItem('token', result.token);
          localStorage.setItem('display-name', `${ result.firstName }, ${ result.lastName }`);
          this.cachingService.clearAll();
          this.route.navigateByUrl('home');
        }, 
        (error) => {
          this.error = error.error.message;
        });
  }
}