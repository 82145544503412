import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingAnimationService } from 'src/app/common/loading-animation/loading-animation.service';
import { CustomerOrder } from 'src/app/models/customer-order.model';
import { Customer } from 'src/app/models/customer.model';
import { DataShareService } from 'src/app/services/common/data-share.service';
import { ICachingService } from 'src/app/services/common/interface/ICachingService';
import { CustomerService } from 'src/app/services/customer.service';

@Component({
  selector: 'app-customer-po-list',
  templateUrl: './customer-po-list.component.html',
  styleUrls: ['./customer-po-list.component.css']
})
export class CustomerPoListComponent implements OnInit {

  private customerId: number;
  private customers: Customer[] = [];
  private customerOrders: CustomerOrder[] = [];

  constructor(private loadingAnimationService: LoadingAnimationService, private router: Router, private sharedDataService: DataShareService, 
              private cacheService: ICachingService, private customerService: CustomerService, private activatedRoute: ActivatedRoute) { 
    
  }

  ngOnInit() {
    this.customerService.getAllCustomers(false).subscribe((customers) => {
      this.customers = customers;
      this.cacheService.setData(this.customers, 'customer-list');
      this.customerId = this.activatedRoute.snapshot.params.id;
      if (this.customerId > 0) {
        this.customerSelected();
      }
    });
  }

  customerSelected() {
    this.getAllCustomerOrders(this.activatedRoute.snapshot.params.cache == 'true');
  }

  getAllCustomerOrders(clearCache: boolean = false) {
    this.loadingAnimationService.show();
    this.customerService.getAllCustomerOrders(clearCache, this.customerId)
        .subscribe(
          (customerorders) => {
            this.customerOrders = customerorders;
            this.cacheService.setData(this.customerOrders, 'customer-order-list');
          },
          (error) => console.log(error),
          () => this.loadingAnimationService.hide()
        ); 
  }

  navigateToDetails(customerOrder: CustomerOrder) {
    this.sharedDataService.changeMessage(customerOrder);
    this.router.navigateByUrl(`home/customerpo/detail`);
  }
}