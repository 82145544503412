import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomerOrder } from 'src/app/models/customer-order.model';
import { DataShareService } from 'src/app/services/common/data-share.service';

@Component({
  selector: 'app-customer-po-detail',
  templateUrl: './customer-po-detail.component.html',
  styleUrls: ['./customer-po-detail.component.css']
})
export class CustomerPoDetailComponent implements OnInit {

  private customerOrder: CustomerOrder;

  constructor(private router: Router, private sharedDataService: DataShareService) { }

  ngOnInit() {
    this.sharedDataService.currentMessage.subscribe((data: CustomerOrder) => this.customerOrder = data);
  }

  navigateBack() {
    this.router.navigateByUrl(`home/customerpo/list/false/${ this.customerOrder.customerId }`);
  }
}
