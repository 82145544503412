import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingAnimationService } from 'src/app/common/loading-animation/loading-animation.service';
import { SupplierInvoice } from 'src/app/models/supplier-invoice.model';
import { DataShareService } from 'src/app/services/common/data-share.service';
import { ICachingService } from 'src/app/services/common/interface/ICachingService';
import { SupplierService } from 'src/app/services/supplier.service';

@Component({
  selector: 'app-supplier-invoice-list',
  templateUrl: './supplier-invoice-list.component.html',
  styleUrls: ['./supplier-invoice-list.component.css']
})
export class SupplierInvoiceListComponent implements OnInit {

  private supplierInvoices: SupplierInvoice[] = [];

  constructor(private loadingAnimationService: LoadingAnimationService, private router: Router, private sharedDataService: DataShareService, 
    private cacheService: ICachingService, private supplierService: SupplierService, private activatedRoute: ActivatedRoute) { 

}

  ngOnInit() {
    this.getAllSupplierInvoices(this.activatedRoute.snapshot.params.cache == 'true');
  }

  getAllSupplierInvoices(clearCache: boolean = false) {
    this.loadingAnimationService.show();
    this.supplierService.getAllSupplierInvoice(clearCache)
        .subscribe(
          (supplierinvoices) => {
            this.supplierInvoices = supplierinvoices;
            this.cacheService.setData(this.supplierInvoices, 'supplier-invoice-list');
          },
          (error) => console.log(error),
          () => this.loadingAnimationService.hide()
        ); 
  }

  navigateToDownloads(supplierInvoice: SupplierInvoice) {
    this.sharedDataService.changeMessage(supplierInvoice);
    this.router.navigateByUrl('home/supplierinvoice/detail');
  }

  toggleReceive(supplierInvoice: SupplierInvoice) {
    this.loadingAnimationService.show();
    if (supplierInvoice.isInvoiceReceived) {
      this.supplierService.unReceiveInvoice(supplierInvoice.supplierId, supplierInvoice.id)
          .subscribe(
            () => {
              alert('Invoice unreceived successfully');
              this.loadingAnimationService.hide();
              this.getAllSupplierInvoices(true);
            },
            (error) => {
              alert(error.error);
              this.loadingAnimationService.hide();
            }
          );
    } else {
      this.supplierService.receiveInvoice(supplierInvoice.supplierId, supplierInvoice.id, supplierInvoice.warehouseId)
          .subscribe(
            () => {
              alert('Invoice received successfully');
              this.loadingAnimationService.hide();
              this.getAllSupplierInvoices(true);
            },
            (error) => {
              alert(error.error);
              this.loadingAnimationService.hide();
            },
          );
    }
  }
}