import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingAnimationService } from 'src/app/common/loading-animation/loading-animation.service';
import { Customer } from 'src/app/models/customer.model';
import { Shipment } from 'src/app/models/shipment.model';
import { ConfigService } from 'src/app/services/common/config.service';
import { DataShareService } from 'src/app/services/common/data-share.service';
import { ICachingService } from 'src/app/services/common/interface/ICachingService';
import { CustomerService } from 'src/app/services/customer.service';
import { ShipmentService } from 'src/app/services/shipment.service';

@Component({
  selector: 'app-shipment-list',
  templateUrl: './shipment-list.component.html',
  styleUrls: ['./shipment-list.component.css']
})
export class ShipmentListComponent implements OnInit {

  customerId: number;
  customers: Customer[] = [];
  shipments: Shipment[] = [];

  constructor(private loadingAnimationService: LoadingAnimationService, private router: Router, private sharedDataService: DataShareService, private configService: ConfigService,
    private cacheService: ICachingService, private customerService: CustomerService, private activatedRoute: ActivatedRoute, private shipmentService: ShipmentService) { }

  ngOnInit() {
    this.customerService.getAllCustomers(false).subscribe((customers) => {
      this.customers = customers;
      this.cacheService.setData(this.customers, 'customer-list');
      this.customerId = this.activatedRoute.snapshot.params.id;
      if (this.customerId > 0) {
        this.customerSelected();
      }
    });
  }

  customerSelected() {
    this.getShipmentsForCustomer(this.activatedRoute.snapshot.params.cache == 'true');
  }

  navigateToUploadPOS(shipment: Shipment) {
    this.sharedDataService.changeMessage(shipment);
    this.router.navigateByUrl('home/shipment/upload-pos');
  }

  navigateToBarcodeScanning(shipment: Shipment) {
    this.sharedDataService.changeMessage(shipment);
    this.router.navigateByUrl('home/shipment/scan-barcode');
  }

  getShipmentsForCustomer(clearCache: boolean = false) {
    this.loadingAnimationService.show();
    this.shipmentService.getShipmentsForCustomer(clearCache, this.customerId)
        .subscribe((shipments) => {
          this.shipments = shipments;
          this.cacheService.setData(this.shipments, `shipments-list-${ this.customerId }`);
          this.loadingAnimationService.hide();
        }, 
        (error) => console.log(error.error));
  }

  downloadPOS(shipmentId: number) {
    window.open(`${this.configService.Settings.apiServerHost}/${ this.configService.Settings.fileUri }/POS/${ shipmentId }`);
  }
}