import { Injectable } from '@angular/core';
import { ApiService } from './common/api.service';
import { CompanyService } from './company.service';
import { Observable } from 'rxjs';
import { ConfigService } from './common/config.service';
import { Part } from '../models/part.model';

@Injectable({
  providedIn: 'root'
})
export class PartsService {

  constructor(private apiService: ApiService, private companyService: CompanyService, private configService: ConfigService) { }

  getAllParts(clearCache: boolean = false): Observable<Part[]> {
    var companyId = this.companyService.getCurrentlyLoggedInCompanyId();
    return this.apiService.get<Part[]>('parts-list', clearCache, `${ this.configService.Settings.apiServerHost }/${ this.configService.Settings.partsUri }/${ companyId }`);
  }
}
