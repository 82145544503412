import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SupplierOrder } from 'src/app/models/supplier-order.model';
import { DataShareService } from 'src/app/services/common/data-share.service';

@Component({
  selector: 'app-supplier-po-detail',
  templateUrl: './supplier-po-detail.component.html',
  styleUrls: ['./supplier-po-detail.component.css']
})
export class SupplierPoDetailComponent implements OnInit {

  private supplierOrder: SupplierOrder;

  constructor(private router: Router, private sharedDataService: DataShareService) { }

  ngOnInit() {
    this.sharedDataService.currentMessage.subscribe((data: SupplierOrder) => {
      this.supplierOrder = data;
      this.supplierOrder.poDetails.forEach(item => {
        item.total = (item.qty * item.unitPrice).toFixed(2);
      });
    });
  }

  navigateBack() {
    this.router.navigateByUrl(`home/supplierpo/list/false/${ this.supplierOrder.supplierId }`);
  }

}
