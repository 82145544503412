import { Component, OnInit } from '@angular/core';
import { PartsService } from 'src/app/services/parts.service';
import { Part } from 'src/app/models/part.model';
import { LoadingAnimationService } from 'src/app/common/loading-animation/loading-animation.service';
import { Router } from '@angular/router';
import { DataShareService } from 'src/app/services/common/data-share.service';
import { ICachingService } from 'src/app/services/common/interface/ICachingService';

@Component({
  selector: 'app-inventory-list',
  templateUrl: './inventory-list.component.html',
  styleUrls: ['./inventory-list.component.css']
})
export class InventoryListComponent implements OnInit {

  private parts: Part[] = [];

  constructor(private loadingAnimationService: LoadingAnimationService, private router: Router, private sharedDataService: DataShareService, 
              private cacheService: ICachingService, private partService: PartsService) { }

  ngOnInit() {
    this.listAllParts();
  }

  listAllParts(clearCache: boolean = false) {
    this.loadingAnimationService.show();
      this.partService.getAllParts(clearCache)
          .subscribe(
            (parts) => {
              this.parts = parts; //.filter(p => p.isActive == true);
              this.cacheService.setData(this.parts, 'parts-list');
            },
            (error) => console.log(error),
            () => this.loadingAnimationService.hide()
          );
  }

  navigateToDetails(part: Part) {
    this.sharedDataService.changeMessage(part);
    this.router.navigateByUrl(`home/inventory/detail/${ part.id }`);
  }
}