import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  inventory() {
    this.router.navigateByUrl('home/inventory/list');
  }

  navigateToCustomerPurchaseOrder() {
    this.router.navigateByUrl('home/customerpo/list/true/0');
  }

  navigateToSupplierPurchaseOrder() {
    this.router.navigateByUrl('home/supplierpo/list/true/0');
  }

  navigateToReceiveSupplierInvoiceList() {
    this.router.navigateByUrl('home/supplierinvoice/list/true');
  }

  navigateToShipments() {
    this.router.navigateByUrl('home/shipment/list/true/0');
  }
}
