import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CustomerOrder } from '../models/customer-order.model';
import { Customer } from '../models/customer.model';
import { ApiService } from './common/api.service';
import { ConfigService } from './common/config.service';
import { CompanyService } from './company.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private apiService: ApiService, private companyService: CompanyService, private configService: ConfigService) { }

  getAllCustomers(clearCache: boolean = false): Observable<Customer[]> {
    var companyId = this.companyService.getCurrentlyLoggedInCompanyId();
    return this.apiService.get<Customer[]>('customer-list', clearCache, `${ this.configService.Settings.apiServerHost }/${ this.configService.Settings.customerUri }/${ companyId }`);

  }

  getAllCustomerOrders(clearCache: boolean = false, customerId: number) : Observable<CustomerOrder[]> {
    var companyId = this.companyService.getCurrentlyLoggedInCompanyId();
    return this.apiService.get<CustomerOrder[]>('customer-order-list', clearCache, `${ this.configService.Settings.apiServerHost }/${ this.configService.Settings.ordersUri }/${ companyId }/${ customerId }`);
  }
}