import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingAnimationService } from 'src/app/common/loading-animation/loading-animation.service';
import { SupplierOrder } from 'src/app/models/supplier-order.model';
import { Supplier } from 'src/app/models/supplier.model';
import { DataShareService } from 'src/app/services/common/data-share.service';
import { ICachingService } from 'src/app/services/common/interface/ICachingService';
import { SupplierService } from 'src/app/services/supplier.service';

@Component({
  selector: 'app-supplier-po-list',
  templateUrl: './supplier-po-list.component.html',
  styleUrls: ['./supplier-po-list.component.css']
})
export class SupplierPoListComponent implements OnInit {

  private supplierId: number;
  private suppliers: Supplier[] = [];
  private supplierOrders: SupplierOrder[] = [];

  constructor(private loadingAnimationService: LoadingAnimationService, private router: Router, private sharedDataService: DataShareService, 
    private cacheService: ICachingService, private supplierService: SupplierService, private activatedRoute: ActivatedRoute) { 

}

  ngOnInit() {
    this.supplierService.getAllSuppliers(false)
        .subscribe(
          (suppliers) => {
            this.suppliers = suppliers;
            this.cacheService.setData(this.suppliers, 'supplier-list');
            this.supplierId = this.activatedRoute.snapshot.params.id;
            if (this.supplierId > 0) {
              this.supplierSelected();
            }
          }
        );
  }

  supplierSelected(clearCache: boolean = false) {
    this.getAllSupplierOrders(this.activatedRoute.snapshot.params.cache == 'true');
  }

  getAllSupplierOrders(clearCache: boolean = false) {
    this.loadingAnimationService.show();
    this.supplierService.getAllSupplierOrders(clearCache, this.supplierId)
        .subscribe(
          (supplierorder) => {
            this.supplierOrders = supplierorder;
            this.cacheService.setData(this.supplierOrders, 'supplier-order-list');
          },
          (error) => console.log(error),
          () => this.loadingAnimationService.hide()
        ); 
  }

  navigateToDetails(supplierOrder: SupplierOrder) {
    this.sharedDataService.changeMessage(supplierOrder);
    this.router.navigateByUrl(`home/supplierpo/detail`);
  }
}