import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Shipment } from '../models/shipment.model';
import { ApiService } from './common/api.service';
import { ConfigService } from './common/config.service';
import { CompanyService } from './company.service';

@Injectable({
  providedIn: 'root'
})
export class ShipmentService {

  constructor(private apiService: ApiService, private companyService: CompanyService, private configService: ConfigService) { }

  getAllShipments(clearCache: boolean = false): Observable<Shipment[]> {
    var companyId = this.companyService.getCurrentlyLoggedInCompanyId();
    return this.apiService.get<Shipment[]>('shipments-list', clearCache, `${ this.configService.Settings.apiServerHost }/${ this.configService.Settings.shipmentUri }/${ companyId }`);
  }

  getShipmentsForCustomer(clearCache: boolean = false, customerId: number): Observable<Shipment[]> {
    var companyId = this.companyService.getCurrentlyLoggedInCompanyId();
    return this.apiService.get<Shipment[]>(`shipments-list-${ customerId }`, clearCache, `${ this.configService.Settings.apiServerHost }/${ this.configService.Settings.shipmentUri }/${ companyId }/customer/${ customerId }`);
  }

  completeScanning(shipment: Shipment, barcodeValue: string) {
    return this.apiService.put(shipment, `${this.configService.Settings.apiServerHost}/${this.configService.Settings.shipmentUri}/scan/${barcodeValue}`);
  }
}