import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Shipment } from '../models/shipment.model';
import { SupplierInvoice } from '../models/supplier-invoice.model';
import { SupplierOrder } from '../models/supplier-order.model';
import { Supplier } from '../models/supplier.model';
import { ApiService } from './common/api.service';
import { ConfigService } from './common/config.service';
import { CompanyService } from './company.service';

@Injectable({
  providedIn: 'root'
})
export class SupplierService {

  constructor(private apiService: ApiService, private companyService: CompanyService, private configService: ConfigService) { }

  getAllSuppliers(clearCache: boolean = false): Observable<Supplier[]> {
    var companyId = this.companyService.getCurrentlyLoggedInCompanyId();
    return this.apiService.get<Supplier[]>('supplier-list', clearCache, `${ this.configService.Settings.apiServerHost }/${ this.configService.Settings.supplierUri }/${ companyId }`);
  }

  getAllSupplierOrders(clearCache: boolean = false, supplierId: number) : Observable<SupplierOrder[]> {
    var companyId = this.companyService.getCurrentlyLoggedInCompanyId();
    return this.apiService.get<SupplierOrder[]>('supplier-order-list', clearCache, `${ this.configService.Settings.apiServerHost }/${ this.configService.Settings.supplierOrdersUri }/${ companyId }/${ supplierId }`);
  }

  getAllSupplierInvoice(clearCache: boolean = false): Observable<SupplierInvoice[]> {
    var companyId = this.companyService.getCurrentlyLoggedInCompanyId();
    return this.apiService.get<SupplierInvoice[]>('supplier-invoice-list', clearCache, `${ this.configService.Settings.apiServerHost }/${ this.configService.Settings.supplierInvoiceUri }/${ companyId }`);
  }

  receiveInvoice(supplierId: number, invoiceId: number, warehouseId: number) {
    return this.apiService.post<number>(invoiceId, `${ this.configService.Settings.apiServerHost }/${ this.configService.Settings.postSupplierinvoiceUri }/receive/${ invoiceId }/${ warehouseId }`);
  }

  unReceiveInvoice(supplierId: number, invoiceId: number) {
    return this.apiService.put<number>(invoiceId, `${ this.configService.Settings.apiServerHost }/${ this.configService.Settings.postSupplierinvoiceUri }/unreceive/${ invoiceId }`);
  }
}