import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataShareService } from 'src/app/services/common/data-share.service';
import { Part } from 'src/app/models/part.model';

@Component({
  selector: 'app-inventory-detail',
  templateUrl: './inventory-detail.component.html',
  styleUrls: ['./inventory-detail.component.css']
})
export class InventoryDetailComponent implements OnInit {

  private part: Part;

  constructor(private router: Router, private sharedDataService: DataShareService) { }

  ngOnInit() {
    this.sharedDataService.currentMessage.subscribe((data: Part) => this.part = data);
  }

  navigateBack() {
    this.router.navigateByUrl('home/inventory/list');
  }
}